/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.notifications {
    --background: rgba(255, 255, 255, 0.95);
}

.video-mask-landscape {
    position: absolute;

    top: 0px;
    width: 100%;
    // height: 100%;
    // max-width: 90vw;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;

}
.video-mask-portrait {
    position: absolute;

    top: 0px;
    height: 100%;
    max-height: 80vh;
    // width: 100%;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.bold{
    font-weight: bold;
}
.button {
    text-transform: none;
}

.menu {
    display: flex;
}
.menu div {
    flex: auto;
}

ion-button{
    font-family: "HelveticaltBold";
}
ion-row{
    font-family: "Helvetica";
}
